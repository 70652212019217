import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import { DataContext } from "../../../contexts/DataContext";
import { 
  StyledTitle, 
  StyledSubtitle, 
  StyledEpisode,
  StyledEpisodeImage,
  StyledEpisodeText,
} from "../style";

const Episode = ({ episode, isImageLeft }) => {
  const { pageImages } = useContext(DataContext);
  const imageData = pageImages.find(e => e.path === episode.image);

  return (
    <StyledEpisode isImageLeft={isImageLeft}>
      <StyledEpisodeImage>
        <GatsbyImage style={{ width: "inherit", borderRadius: 15 }} image={getImage(imageData)} alt={imageData.alt} />
        <p>{episode.caption}</p>
      </StyledEpisodeImage>
      <StyledEpisodeText isImageLeft={isImageLeft}>
        <StyledTitle dangerouslySetInnerHTML={{ __html: episode.title }}/>
        <StyledSubtitle dangerouslySetInnerHTML={{ __html: episode.description }}/>
        <StyledSubtitle><span style={{ fontStyle: "italic", opacity: 0.5 }}>{episode.quote}</span> - {episode.quoteOwner}</StyledSubtitle>
      </StyledEpisodeText>
    </StyledEpisode>
  );
};

export default Episode;
